<template>
  <div class="container">
    <div class="text-center row">
      <div class="col-md-8">
        <Carousel class="mb-4 mb-md-0"></Carousel>
      </div>
      <div class="col-md-4">
        <div class="right-img riva mb-4 p-3">
          <h3 style="margin-top: 150px" class="text-white">Riva model</h3>
        </div>
        <!-- <img
          class="my-1 d-block w-100 position-relative"
          style="width: 100%; height: 100%"
          src="../assets/img/riva/riva-1.jpg"
          alt=""
        /> -->

        <div class="right-img zerda mb-4 p-3">
          <h3 style="margin-top: 150px" class="text-white">Zerda Model</h3>
        </div>
        <!-- <img
          class="my-1 d-block w-100"
          style="width: 100%; height: 100%"
          src="../assets/img/zerda/zerda-1.jpg"
          alt=""
        /> -->
      </div>
    </div>
  </div>
</template>

<style scoped>
* {
  box-sizing: border-box;
}
.container {
  background-color: #fff;
  width: 100%;
}
.riva {
  width: 100%;
  background: url("../assets/img/riva/riva-1.jpg") no-repeat center;
}
.zerda {
  background: url("../assets/img/zerda/zerda-1.jpg") no-repeat center;
}
.right-img {
  height: 14.25rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
<script>
import Carousel from "../components/Carousel.vue";

export default {
  components: {
    Carousel,
  },
};
</script>
