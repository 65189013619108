<template>
  <div>
    <Header />
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <Footer />
  </div>
</template>

<script>
import Header from "./components/shared/Header.vue";

import Footer from "./components/shared/Footer.vue";
import HomePage from "./pages/HomePage.vue";
import ProductPage from "./pages/ProductPage.vue";
import CarouselProduct from "./components/CarouselProduct.vue";
import ContactPage from "./pages/ContactPage.vue";

export default {
  name: "App",
  components: {
    HomePage,
    ContactPage,
    ProductPage,
    CarouselProduct,
    Footer,

    Header,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  height: 100%;
}
CSS .fade-enter {
  opacity: 0.4;
}
.fade-enter-active {
  transition: opacity 0.2s ease-out;
}
.fade-leave-active {
  transition: opacity 0.2s ease-out;
  opacity: 0;
}
</style>
