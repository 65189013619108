import HomePage from './pages/HomePage.vue'
import ProductPage from './pages/ProductPage.vue'
import ContactPage from './pages/ContactPage.vue'
import AboutPage from './pages/AboutPage.vue'




export const routes = [
    {
        path:'' , component : HomePage
    },
    {
        path:'/urunler' ,component: ProductPage
    },
    {
        path:'/iletisim',component:ContactPage
    },
    {
        path:'/hakkimizda',component:AboutPage
    },
    {
        path : "*", redirect : "/"
    }
]