<template>
  <div>
    <CarouselProduct />
  </div>
</template>

<script>
import Header from "../components/shared/Header.vue";
import Footer from "../components/shared/Footer.vue";
import ProductCard from "../components/ProductCard.vue";
import CarouselProduct from "../components/CarouselProduct.vue";

export default {
  components: {
    Header,
    Footer,
    ProductCard,
    CarouselProduct,
  },
};
</script>
