<template>
  <div>
    <section class="ftco-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6 text-center mb-5">
            <h2 class="heading-section">İletişim Bilgilerimiz</h2>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div class="wrapper">
              <div class="row no-gutters mb-5">
                <div class="col-md-6">
                  <div class="contact-wrap w-100 p-md-5 p-4">
                    <h3 class="mb-4">Bizimle İletişime Geçin</h3>
                    <div id="form-message-warning" class="mb-4"></div>
                    <form
                      method="POST"
                      id="contactForm"
                      name="contactForm"
                      class="contactForm"
                    >
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group m-2">
                            <label class="label" for="name">İsim Soyisim</label>
                            <input
                              type="text"
                              class="form-control"
                              name="name"
                              id="name"
                              placeholder="Name"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group m-2">
                            <label class="label" for="email"
                              >Mail Adresiniz</label
                            >
                            <input
                              type="email"
                              class="form-control"
                              name="email"
                              id="email"
                              placeholder="Email"
                            />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group m-2">
                            <label class="label" for="subject">Konu</label>
                            <input
                              type="text"
                              class="form-control"
                              name="subject"
                              id="subject"
                              placeholder="Subject"
                            />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group m-2">
                            <label class="label" for="#">Mesaj</label>
                            <textarea
                              name="message"
                              class="form-control"
                              id="message"
                              cols="30"
                              rows="4"
                              placeholder="Message"
                            ></textarea>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group m-2">
                            <input
                              type="submit"
                              value="Send Message"
                              class="btn btn-primary"
                            />
                            <div class="submitting"></div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-md-6 d-flex">
                  <div class="w-100 h-100 me-auto ms-auto" id="map">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24079.52252437645!2d28.8946196!3d41.0265617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa5f5571cea07%3A0xd75cb46f186339b1!2zRXJlbCwgMzQxMDAgxLBraXRlbGxpIE9zYi9CYcWfYWvFn2VoaXIvxLBzdGFuYnVs!5e0!3m2!1str!2str!4v1662460219446!5m2!1str!2str"
                      width="600"
                      height="450"
                      style="border: 0"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="dbox w-100 text-center">
                    <div
                      class="icon d-flex align-items-center justify-content-center"
                    >
                      <span class="m-3 bi bi-geo-alt-fill"></span>
                    </div>
                    <div class="text">
                      <p>
                        <i class="fas fa-home me-3 text-secondary"></i> Ziya
                        Gökalp Mh. Dersan Koop. San Sit. S.6.B No:5
                        <br />İkitelli-Başakşehir / İstanbul
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="dbox w-100 text-center">
                    <div
                      class="icon d-flex align-items-center justify-content-center"
                    >
                      <span class="m-3 bi bi-phone-fill"></span>
                    </div>
                    <div class="text">
                      <p>
                        <i class="me-2 fa-solid fa-phone"></i>
                        <span class="me-1">Telefon:</span>

                        <a
                          class="text-blue text-decoration-none"
                          href="tel:+535 349 68 56"
                          >(535) 349 68 56</a
                        >
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="dbox w-100 text-center">
                    <div
                      class="icon d-flex align-items-center justify-content-center"
                    >
                      <span class="m-3 bi bi-envelope-fill"></span>
                    </div>
                    <div class="text">
                      <p>
                        <i class="fas fa-envelope mx-1 text-secondary"></i>
                        <span>Email:</span>

                        <a
                          class="text-decoration-none"
                          href="mailto:aksoycatalkasik@outlook.com"
                        >
                          aksoycatalkasik@outlook.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Header from "../components/shared/Header.vue";
import Footer from "../components/shared/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
};
</script>
<style></style>
