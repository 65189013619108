<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="logo">
            <img
              class="w-100 h-100 rounded shadow"
              src="../assets/img/aksoy.png"
              alt=""
            />
          </div>
        </div>
        <div
          style="transition: transform"
          class="rounded col-md-6 d-flex align-items-center justify-content-center shadow flex-column"
        >
          <h4 style="text-shadow: 1px 1px">Biz Kimiz ?</h4>
          <h3
            style="font-weight: 500px; text-shadow: 1px 1px; font-style: italic"
          >
            Aksoy Çatal Kaşık Bıçak,
          </h3>
          <span>olarak</span> 1985 yılında çelik eşya üretimine başlayan
          firmamız ilerleyen süreçte ticaret ve pazarlama faaliyetleri ile bugün
          züccaciye sektörünün önde gelen kuruluşları arasında yerini
          almıştır.Türkiye’yi kapsayan ticaret ağı ve Avrupanın birçok ülkesine
          yaptığı ihracatı ile bu sektörede adından kalitesi ile sıkça söz
          ettiren firmamız ürünlerinin kalitesini avrupa menşeyli pazlanmaz
          çelik hammadde kullanarak sağlamaktadır.Daima yeni tasarımlarıyla
          sofralarınızı süslemeye ve müşterilerine daha iyiyi sunmayı amaçlayan
          firmamız hammadde seçiminden üretimine, paketlemeden müşteri ile
          buluşmasına kadar tüm detaylarda süreci titizlik ile yürüterek müşteri
          güvenini kazanmıştır.1985 yılından bu zamana kadar olan tecrübesiyle
          güven ilkesini ürün kalitesine verdiği önemle ve mamüllerine verdiği
          ömür boyu garanti ile sağlamış olup sektördeki faaliyetlerine
          başarıyla devam etmektedir.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/shared/Header.vue";
import Footer from "../components/shared/Footer.vue";

export default {
  components: {
    Footer,
    Header,
  },
};
</script>
