<template>
  <div class="">
    <div v-for="i in 1" :key="i" class="">
      <div v-for="i in 1" :key="i" class="">
        <Card></Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "./Card.vue";
export default {
  components: {
    Card,
  },
};
</script>
