import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import {routes} from "./routes"


Vue.config.productionTip = false
Vue.use(VueRouter);
const router = new VueRouter(
  {
    routes,
    mode:'history'
    
  }
)
new Vue({
  render: h => h(App),
  router
}).$mount('#app')


// MODAL-IMG KISMI

document.addEventListener("click",function(e){
  if(e.target.classList.contains("gallery-item")){
     const src=e.target.getAttribute("src");
    document.querySelector(".modal-img").src = src;
    const myModal = new bootstrap.Modal(document.getElementById('gallery-modal'))
    myModal.show();
  }
})
