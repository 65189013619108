<template>
  <div>
    <MainArticle></MainArticle>
    <MiniProducts></MiniProducts>
  </div>
</template>

<script>
import Header from "../components/shared/Header.vue";
import MainArticle from "../components/MainArticle.vue";
import MiniProducts from "../components/MiniProducts.vue";
import Footer from "../components/shared/Footer.vue";

export default {
  components: {
    Header,
    MainArticle,
    MiniProducts,
    Footer,
  },
};
</script>

<style scoped></style>
